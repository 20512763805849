import React from "react";

import {
  List,
  Datagrid,
  NumberField,
  TextField,
  BooleanField,
  DateField,
  ReferenceField,
  TextInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  Create,
  Edit,
  BooleanInput,
  NumberInput,
  useRecordContext,
  EditButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  Button,
  useNotify,
  useGetList,
} from "react-admin";

import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import MuiButton from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MuiTextField from "@mui/material/TextField";
import MuiAutocomplete from "@mui/material/Autocomplete";

import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { httpClient } from "../lib/httpClient";

const DeviceAppBarTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <span> {"Device " + (record.serial ? record.serial : record.name)}</span>
  );
};

const WifiProvisionField = () => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <span>
      <Link
        href={
          "https://espressif.github.io/esp-jumpstart/qrcode.html?data=" +
          JSON.stringify({
            ver: "v1",
            name: record.name,
            pop: "iCmKwgYS7x", // hardcoded in firmware
            transport: "softap",
          })
        }
        target="_new"
        title="Wifi Provisioning QR Code"
      >
        Wifi QR
      </Link>
    </span>
  );
};

const objectToRows = (obj) => {
  if (!obj) return null;

  return Object.keys(obj)
    .sort()
    .map((key) => (
      <TableRow key={key}>
        <TableCell>{key}</TableCell>
        <TableCell>{obj[key]}</TableCell>
      </TableRow>
    ));
};

const DevicePropertiesTable = () => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <Table size="small" style={{ width: "inherit" }}>
      <TableBody>
        <TableRow key="online">
          <TableCell>Online</TableCell>
          <TableCell>{record.online ? "yes" : "no"}</TableCell>
        </TableRow>
        <TableRow key="lastSeen">
          <TableCell>Last Online</TableCell>
          <TableCell>{record.lastSeen}</TableCell>
        </TableRow>
        {objectToRows(record.properties)}
      </TableBody>
    </Table>
  );
};

const DeviceSettingsTable = () => {
  const record = useRecordContext();
  if (!record) return null;

  if (!record.settings || Object.keys(record.settings).length === 0) {
    return (
      <Alert severity="info">
        No settings have been reported by this device
      </Alert>
    );
  }

  return (
    <Table size="small" style={{ width: "inherit" }}>
      <TableBody>{objectToRows(record.settings)}</TableBody>
    </Table>
  );
};

const DeviceRecoveryTab = () => {
  const record = useRecordContext();
  if (!record) return null;

  if (!record.recovery) {
    return <Alert severity="info">Device is not in recovery mode</Alert>;
  }

  return (
    <>
      <DeviceProvisionButton />
      <Table size="small" style={{ width: "inherit" }}>
        <TableBody>{objectToRows(record.recovery)}</TableBody>
      </Table>
    </>
  );
};
const deviceFilters = [
  <BooleanInput source="online" label="Online" />,
  <ReferenceInput source="customerID" reference="customers">
    <SelectInput optionText="longName" label="Customer" />
  </ReferenceInput>,
  <TextInput source="firmwareName" label="Firmware Name" />,
];

export const DeviceList = () => (
  <List pagination={null} filters={deviceFilters}>
    <Datagrid bulkActionButtons={false}>
      <NumberField source="id" label="ID" sortable={false} />
      <TextField source="name" label="Name" sortable={false} />
      <TextField source="serial" label="Serial" sortable={false} />
      <ReferenceField
        label="Customer"
        source="customerID"
        reference="customers"
        sortable={false}
      >
        <TextField source="longName" />
      </ReferenceField>
      <BooleanField source="online" label="Online" sortable={false} />
      <TextField
        source="properties.firmwareVersion"
        label="FW Version"
        sortable={false}
      />
      <DateField source="lastSeen" label="Last Seen" sortable={false} />
      <WifiProvisionField />
      <EditButton />
    </Datagrid>
  </List>
);

const DeviceProvisionButton = () => {
  const [open, setOpen] = React.useState(false);
  const [running, setRunning] = React.useState(false);

  const notify = useNotify();
  const record = useRecordContext();
  if (!record) return null;

  const deviceDescription = record.serial || "Device ID " + record.id;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleProvision = () => {
    setRunning(true);
    httpClient("/api/v2/devices/" + record.id + "/provision", {
      method: "POST",
    })
      .then(async (resp) => {
        setOpen(false);
        setRunning(false);
        if (resp.ok) {
          notify(deviceDescription + " provisioned", { type: "success" });
        } else {
          notify(deviceDescription + " provision failed", { type: "error" });
          console.log(resp);
        }
      })
      .catch((error) => {
        setOpen(false);
        setRunning(false);
        notify(deviceDescription + " provision failed", { type: "error" });
        console.log(error);
      });
  };

  return (
    <>
      <Button label="Provision" size="medium" onClick={handleOpen}>
        <RotateLeftIcon />
      </Button>
      <Dialog open={open}>
        <DialogTitle>Confirm Device Provision</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to provision <b>{deviceDescription}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton autoFocus onClick={handleCancel} disabled={running}>
            Cancel
          </MuiButton>
          <MuiButton onClick={handleProvision} disabled={running}>
            Provision
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DeviceRebootButton = () => {
  const [open, setOpen] = React.useState(false);
  const [running, setRunning] = React.useState(false);

  const notify = useNotify();
  const record = useRecordContext();
  if (!record) return null;

  const deviceDescription = record.serial || "Device ID " + record.id;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleReboot = () => {
    setRunning(true);
    httpClient("/api/v2/devices/" + record.id + "/reboot", {
      method: "POST",
    })
      .then(async (resp) => {
        setOpen(false);
        setRunning(false);
        if (resp.ok) {
          notify(deviceDescription + " rebooted", { type: "success" });
        } else {
          notify(deviceDescription + " reboot failed", { type: "error" });
          console.log(resp);
        }
      })
      .catch((error) => {
        setOpen(false);
        setRunning(false);
        notify(deviceDescription + " reboot failed", { type: "error" });
        console.log(error);
      });
  };

  return (
    <>
      <Button
        label="Reboot"
        size="medium"
        disabled={!record.online}
        onClick={handleOpen}
      >
        <RotateLeftIcon />
      </Button>
      <Dialog open={open}>
        <DialogTitle>Confirm Reboot</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reboot <b>{deviceDescription}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton autoFocus onClick={handleCancel} disabled={running}>
            Cancel
          </MuiButton>
          <MuiButton onClick={handleReboot} disabled={running}>
            Reboot
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const FirmwaresAutocomplete = ({ onSelectionChange }) => {
  const { data, isLoading, error } = useGetList("firmware");

  if (isLoading) return "Loading...";
  if (error) return "Error";

  const onChange = (event, value, reason) => {
    if (value) {
      onSelectionChange(value.name);
    } else {
      onSelectionChange(undefined);
    }
  };

  return (
    <MuiAutocomplete
      autoFocus
      options={data.sort((b, a) => a.name.localeCompare(b.name))}
      getOptionLabel={(obj) => obj.name}
      renderInput={(params) => <MuiTextField {...params} label="Firmware" />}
      onChange={onChange}
    />
  );
};

const DeviceOTAButton = () => {
  const [open, setOpen] = React.useState(false);
  const [running, setRunning] = React.useState(false);
  const [firmwareBin, setFirmwareBin] = React.useState();

  const notify = useNotify();
  const record = useRecordContext();
  if (!record) return null;

  const deviceDescription = record.serial || "Device ID " + record.id;

  const handleOpen = () => {
    setOpen(true);
    setRunning(false);
    setFirmwareBin(undefined);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleOTA = () => {
    setRunning(true);
    httpClient("/api/v2/devices/" + record.id + "/ota", {
      method: "POST",
      body: JSON.stringify({ firmware: firmwareBin }),
    })
      .then(async (resp) => {
        setOpen(false);
        setRunning(false);
        if (resp.ok) {
          notify(deviceDescription + " OTA initiated", { type: "success" });
        } else {
          notify(deviceDescription + " OTA failed", { type: "error" });
          console.log(resp);
        }
      })
      .catch((error) => {
        setOpen(false);
        setRunning(false);
        notify(deviceDescription + " OTA failed", { type: "error" });
        console.log(error);
      });
  };

  return (
    <>
      <Button
        label="OTA"
        size="medium"
        disabled={!record.online}
        onClick={handleOpen}
      >
        <SettingsInputAntennaIcon />
      </Button>
      <Dialog open={open}>
        <DialogTitle>Confirm OTA Firmware Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            What firmware do you want to send to <b>{deviceDescription}</b>?
          </DialogContentText>
          <DialogContentText style={{ fontSize: "70%" }}>
            Currently running:{" "}
            <b>
              {record.properties.firmwareName}-
              {record.properties.firmwareVersion}
            </b>
          </DialogContentText>
          <FirmwaresAutocomplete onSelectionChange={setFirmwareBin} />
        </DialogContent>
        <DialogActions>
          <MuiButton autoFocus onClick={handleCancel} disabled={running}>
            Cancel
          </MuiButton>
          <MuiButton onClick={handleOTA} disabled={!firmwareBin || running}>
            Send
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DeviceSettingsButton = () => {
  const [open, setOpen] = React.useState(false);
  const [running, setRunning] = React.useState(false);
  const [settingField, setSettingField] = React.useState();
  const [settingValue, setSettingValue] = React.useState();

  const notify = useNotify();
  const record = useRecordContext();
  if (!record) return null;

  const deviceDescription = record.serial || "Device ID " + record.id;

  const handleOpen = () => {
    setOpen(true);
    setRunning(false);
    setSettingField(undefined);
    setSettingValue(undefined);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleSettings = () => {
    var payload = {};
    payload[settingField] = settingValue;
    setRunning(true);
    httpClient("/api/v2/devices/" + record.id + "/settings", {
      method: "PATCH",
      body: JSON.stringify(payload),
    })
      .then(async (resp) => {
        setOpen(false);
        setRunning(false);
        if (resp.ok) {
          notify(deviceDescription + " setting change initiated", {
            type: "success",
          });
        } else {
          notify(deviceDescription + " setting change failed", {
            type: "error",
          });
          console.log(resp);
        }
      })
      .catch((error) => {
        setOpen(false);
        setRunning(false);
        notify(deviceDescription + " setting change failed", { type: "error" });
        console.log(error);
      });
  };

  return (
    <>
      <Button
        label="Settings"
        size="medium"
        disabled={!record.online}
        onClick={handleOpen}
      >
        <SettingsSuggestIcon />
      </Button>
      <Dialog open={open}>
        <DialogTitle>Confirm Settings Field Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            What settings field do you want to change on{" "}
            <b>{deviceDescription}</b>?
          </DialogContentText>
          <MuiTextField
            autoFocus
            fullWidth
            label="Field"
            onChange={(e) => {
              setSettingField(e.target.value);
            }}
          />
          <MuiTextField
            autoFocus
            fullWidth
            label="Value"
            onChange={(e) => {
              setSettingValue(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <MuiButton autoFocus onClick={handleCancel} disabled={running}>
            Cancel
          </MuiButton>
          <MuiButton
            onClick={handleSettings}
            disabled={!settingField || !settingValue || running}
          >
            Send
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DeviceEditToolbar = () => (
  <Toolbar style={{ flex: 1, justifyContent: "space-between" }}>
    <SaveButton label="Save" />
    <DeviceSettingsButton />
    <DeviceOTAButton />
    <DeviceRebootButton />
    <DeleteButton size="medium" />
  </Toolbar>
);

const CreateEditForm = (props) => (
  <TabbedForm {...props} toolbar={<DeviceEditToolbar />}>
    <TabbedForm.Tab label="General">
      <TextInput label="ID" source="id" disabled />
      <TextInput source="name" />
      <TextInput source="serial" />
      <ReferenceInput source="customerID" reference="customers">
        <SelectInput optionText="longName" label="Customer" />
      </ReferenceInput>
    </TabbedForm.Tab>
    <TabbedForm.Tab label="Calibration">
      <NumberInput
        label="Calibration Offset"
        source="internal.calibrationOffset"
        helperText="Offset to absorbance value prior to applying calibration (e.g.: -0.012)"
      />
      <NumberInput label="Red Calibration" source="internal.calibrationRed" />
      <NumberInput
        label="White Calibration"
        source="internal.calibrationWhite"
      />
    </TabbedForm.Tab>
    <TabbedForm.Tab label="Status">
      <DevicePropertiesTable />
    </TabbedForm.Tab>
    <TabbedForm.Tab label="Settings">
      <DeviceSettingsTable />
    </TabbedForm.Tab>
    <TabbedForm.Tab label="Recovery">
      <DeviceRecoveryTab />
    </TabbedForm.Tab>
  </TabbedForm>
);

export const DeviceEdit = () => (
  <Edit title={<DeviceAppBarTitle />}>
    <CreateEditForm redirect={false} />
  </Edit>
);

export const DeviceCreate = () => (
  <Create>
    <CreateEditForm />
  </Create>
);
