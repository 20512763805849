import {
  Datagrid,
  List,
  NumberField,
  Pagination,
  TextField,
  SimpleForm,
  Create,
  EditButton,
  NumberInput,
  Show,
  SimpleShowLayout,
  ReferenceInput,
  AutocompleteInput,
  FunctionField,
  ShowButton,
  useRecordContext,
  ArrayField,
  ReferenceField,
  Edit,
} from "react-admin";

import { DetailsButton } from "../lib/detailsButton";
import { RecipeField } from "./samples";
import { CustomDateField } from "../lib/customDatetimeField";

export const TrayLoadList = () => (
  <List perPage={25} pagination={<Pagination rowsPerPageOptions={[]} />}>
    <Datagrid bulkActionButtons={false}>
      <NumberField source="id" sortable={false} />
      <FunctionField
        label="Sample Count"
        source="samples"
        render={(record) => (
          <span>
            {record.samples?.filter((s) => s.sample_id !== null).length}
          </span>
        )}
        sortable={false}
      />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

function fillMissingPositions(arr) {
  const result = [];

  let currentPosition = 1;

  for (const item of arr) {
    while (currentPosition < item.position) {
      result.push({ position: currentPosition, sample_id: null });
      currentPosition++;
    }

    result.push(item);
    currentPosition = item.position + 1;
  }

  while (currentPosition <= 21) {
    result.push({ position: currentPosition, sample_id: null });
    currentPosition++;
  }

  return result;
}

const ReferenceInputCreate = () => {
  const referenceInputs = [];

  for (let i = 0; i < 21; i++) {
    referenceInputs.push(
      <ReferenceInput key={i} source={`positions.${i}`} reference="samples">
        <AutocompleteInput
          optionText={(rec) => `${rec.name} (#${rec.id})`}
          style={{ width: 300 }}
          filterToQuery={(searchText) => ({ search: searchText })}
          label={`Position ${i + 1}`}
        />
      </ReferenceInput>
    );
  }

  return <div>{referenceInputs}</div>;
};

const ReferenceInputEdit = () => {
  const record = useRecordContext();
  const result = fillMissingPositions(record?.samples);
  record.samples = result;
  return (
    <div>
      {record.samples.map((data, index) => (
        <ReferenceInput
          key={index}
          source={`samples[${index}].sample_id`}
          reference="samples"
        >
          <AutocompleteInput
            optionText={(rec) => `${rec.name} (#${rec.id})`}
            style={{ width: 300 }}
            filterToQuery={(searchText) => ({ search: searchText })}
            label={`Position ${data.position}`}
          />
        </ReferenceInput>
      ))}
    </div>
  );
};

export const TrayLoadCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <NumberInput source="id" label="ID" />
      <ReferenceInputCreate />
    </SimpleForm>
  </Create>
);

export const TrayLoadEdit = () => (
  <Edit redirect="list">
    <SimpleForm>
      <NumberInput source="id" label="ID" />
      <ReferenceInputEdit />
    </SimpleForm>
  </Edit>
);

export const TrayLoadShow = () => (
  <Show>
    <SimpleShowLayout>
      <NumberField source="id" />
      <ArrayField source="samples">
        <Datagrid bulkActionButtons={false}>
          <TextField source="position" />
          <ReferenceField
            source="sample_id"
            reference="samples"
            label="Sample ID"
            link={false}
          >
            <TextField source="id" />
          </ReferenceField>
          <ReferenceField
            source="sample_id"
            reference="samples"
            label="Name"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="sample_id"
            reference="samples"
            label="Jira"
            link={false}
          >
            <TextField source="jira" />
          </ReferenceField>
          <ReferenceField
            source="sample_id"
            reference="samples"
            label="Created On"
            link={false}
          >
            <CustomDateField source="createdOn" label="Time" sortable={false} />
          </ReferenceField>
          <ReferenceField
            source="sample_id"
            reference="samples"
            label="Source"
            link={false}
          >
            <TextField source="source" />
          </ReferenceField>

          <ReferenceField
            source="sample_id"
            reference="samples"
            label="Recipe"
            link={false}
          >
            <RecipeField />
          </ReferenceField>
          <ReferenceField
            source="sample_id"
            reference="samples"
            label=""
            link={false}
          >
            <DetailsButton />
          </ReferenceField>
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
