import * as d3 from "d3";
import { useEffect, useState } from "react";
import { useRecordContext } from "react-admin";
import GenerateGradientColors from "./gradient_color";

// Styling
const margin = { top: 20, right: 30, bottom: 30, left: 60 };
const width = 1100 - margin.left - margin.right;
const height = 550 - margin.top - margin.bottom;

const styles = {
  xAxis: {
    left: 650,
    top: 595,
    position: "absolute",
  },
  yAxis: {
    left: 230,
    top: 300,
    position: "absolute",
    transform: "rotate(-90deg)",
  },
};

function GatherData(shots) {
  let data = [];
  let maxY;
  let minY;

  for (let i = 0; i < shots.length; i++) {
    const object = { name: i, value: shots[i].processed };
    data.push(object);

    const max = Math.max.apply(null, shots[i].processed);
    if (!maxY || max > maxY) {
      maxY = max;
    }

    const min = Math.min.apply(null, shots[i].processed);
    if (!minY || min < minY) {
      minY = min;
    }
  }

  return [minY, maxY, data];
}

function SetCanvas(ref, height, width) {
  let svg = d3
    .select(ref)
    .append("svg")
    .attr("id", "canvas")
    .style("height", height)
    .style("width", width);
  return svg;
}

function SetScales(minX, maxX, minY, maxY, width, height, margin) {
  const xScale = d3
    .scaleLinear()
    .domain([minX - 100, maxX + 100])
    .range([margin.left, width - margin.right]);

  const yScale = d3
    .scaleLinear()
    .domain([minY - 1000, maxY])
    .nice()
    .range([height, 0]);

  return { xScale: xScale, yScale: yScale };
}

function SetLinesToCanvas(canvas, cleaned, scales, wavenumber, width, margin) {
  const color = GenerateGradientColors(cleaned.length);
  const legend = canvas
    .append("g")
    .attr("class", "legend")
    .attr("transform", `translate(${width - 100}, ${margin.top})`);

  cleaned.forEach((d, i) => {
    const line = d3
      .line()
      .x((d, i) => scales.xScale(wavenumber[i]))
      .y((d) => scales.yScale(d));

    canvas
      .append("path")
      .data([d.value])
      .attr("class", "line")
      .attr("d", line)
      .attr("fill", "none")
      .style("stroke", color[i])
      .style("stroke-width", 1);

    if (i === 0 || i === cleaned.length - 1) {
      const legendItem = legend
        .append("g")
        .attr("transform", `translate(0, ${i * 1})`);

      legendItem
        .append("rect")
        .attr("width", 10)
        .attr("height", 10)
        .style("fill", color[i]);

      legendItem
        .append("text")
        .attr("x", 20)
        .attr("y", 10)
        .text(d.name + 1);
    }
  });
}

function SetAxesToCanvas(
  canvas,
  scales,
  height,
  marginBottom,
  marginLeft,
  marginTop
) {
  let xAxis = d3.axisBottom().scale(scales.xScale);
  canvas
    .append("g")
    .attr("class", "x-axis")
    .attr("transform", "translate(0," + (height - marginBottom) + ")")
    .call(xAxis);

  // Add scale to Y axis
  let yAxis = d3.axisLeft().scale(scales.yScale);
  canvas
    .append("g")
    .attr("class", "y-axis")
    .attr("transform", "translate(" + marginLeft + "," + marginTop + ")")
    .call(yAxis);
}
const LinePlot = () => {
  const record = useRecordContext();
  const [cleaned, setCleaned] = useState(null);
  const [minY, setMinY] = useState(null);
  const [maxY, setMaxY] = useState(null);
  const wavenumber = record.original.wavenumber;
  const minX = wavenumber[0];
  const maxX = wavenumber[wavenumber.length - 1];
  const shots = record.original.shots;

  useEffect(() => {
    if (!shots) return;
    const [minY, maxY, cleaned] = GatherData(shots);

    setCleaned(cleaned);
    setMinY(minY);
    setMaxY(maxY);
  }, [shots]);

  useEffect(() => {
    if (!cleaned) return;

    const canvas = SetCanvas("#container", height, width);
    const scales = SetScales(minX, maxX, minY, maxY, width, height, margin);

    SetAxesToCanvas(
      canvas,
      scales,
      height,
      margin.bottom,
      margin.left,
      margin.top
    );

    SetLinesToCanvas(canvas, cleaned, scales, wavenumber, width, margin);

    return () => {
      d3.select("#canvas").remove();
    };
  }, [cleaned, maxX, maxY, minX, minY, wavenumber]);

  return (
    <div id="container">
      <p style={styles.xAxis}>wavenumber</p>
      <p style={styles.yAxis}>processed</p>
    </div>
  );
};

export default LinePlot;
