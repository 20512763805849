import React from "react";

import {
  List,
  Datagrid,
  NumberField,
  TextField,
  BooleanField,
  TextInput,
  NumberInput,
  BooleanInput,
  Create,
  SimpleForm,
  Edit,
  email,
  useRecordContext,
  ChipField,
  ArrayField,
  SingleFieldList,
} from "react-admin";

const validateEmail = email();

const ScopeBoolean = ({ source }) => {
  const record = useRecordContext();

  if (record.additionalScopes === undefined) {
    return null;
  }

  return (
    <>
      {record.additionalScopes[source] ? (
        <BooleanField source={`additionalScopes.${source}`} />
      ) : null}
    </>
  );
};

const CustomerChip = () => {
  const record = useRecordContext();
  const color = record.access === "manager" ? "primary" : "default";
  return <ChipField source="customerLongName" color={color} />;
};

const userFilters = [
  <TextInput source="email" label="Email" alwaysOn resettable />,
];

export const UserList = () => (
  <List
    pagination={null}
    sort={{ field: "email", order: "ASC" }}
    filters={userFilters}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <NumberField source="id" label="ID" />
      <TextField source="email" label="Email" />
      <ScopeBoolean source="admin" />
      <ScopeBoolean source="diagnostics" />
      <ScopeBoolean source="additions" />
      <ArrayField source="customerAssociations" label="Customer">
        <SingleFieldList>
          <CustomerChip />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

const InternalPrivilegeSwitches = () => {
  const record = useRecordContext();
  const emailAddress = record.email;
  const disabled = emailAddress.endsWith("@barrelwise.ca") ? false : true;
  return (
    <>
      <BooleanInput
        label="Admin"
        source="additionalScopes.admin"
        disabled={disabled}
      />
      <BooleanInput
        label="Diagnostics"
        source="additionalScopes.diagnostics"
        disabled={disabled}
      />
    </>
  );
};

export const UserEdit = () => (
  <Edit redirect="list">
    <SimpleForm>
      <NumberInput label="ID" source="id" disabled />
      <TextInput label="Email" source="email" validate={validateEmail} />
      <InternalPrivilegeSwitches />
      {/* TODO: make this switch a customer-level setting */}
      <BooleanInput label="Additions" source="additionalScopes.additions" />
    </SimpleForm>
  </Edit>
);

export const UserCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <NumberInput label="ID" source="id" disabled />
      <TextInput label="Email" source="email" validate={validateEmail} />
      <p style={{ color: "red", fontWeight: "bold" }}>
        Warning: Creating this user will immediately send them an invite email.
      </p>
    </SimpleForm>
  </Create>
);
