import { fetchJson } from "./httpClient";
import { GoTrueApi, GoTrueClient } from "@supabase/gotrue-js";

export const auth = new GoTrueClient({ url: "/auth/v1" });
export const authApi = new GoTrueApi({ url: "/auth/v1" });

export const logout = async () => {
  const { error } = await auth.signOut();
  if (error) {
    throw new Error(error.message);
  }
};

export const authProvider = {
  login: async ({ username, password }) => {
    const { error } = await auth.signIn({
      email: username,
      password: password,
    });
    if (error) {
      throw new Error(error.message);
    }
  },

  logout: async () => {
    await logout();
    window.location.replace("/login");
  },

  checkError: (error) => {
    // If an API call fails with 401, then consider us logged out
    const status = error.status;
    if (status === 401) {
      authProvider.logout();
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: async () => {
    const session = auth.session();
    if (!session) {
      throw new Error("Not logged in");
    }
  },
  getPermissions: () => {
    // Assume we have all permissions in RA
    return Promise.resolve();
  },
};

let userinfo;

// Called once to check login and gather userinfo
export async function validateLogin() {
  try {
    userinfo = (await fetchJson("/api/v2/userinfo")).json;
  } catch (error) {
    // This is expected to fail if the session has expired
  }
  console.log("validateLogin complete");
}

export function isValidated() {
  if (userinfo) {
    return true;
  }
  return false;
}

export function getCustomerAssociations() {
  if (userinfo) {
    return userinfo.user.customerAssociations;
  }
}

// A list of scopes that are defined in our server
export const SCOPE = {
  ADMIN: "admin",
  MANAGER: "?-manager",
  MEMBER: "?-member",
  DIAGNOSTICS: "diagnostics",
  ADDITIONS: "additions",
};

export function setScopesCustomerID(custID) {
  SCOPE.MANAGER = custID + "-manager";
  SCOPE.MEMBER = custID + "-member";
}

// Searches the user's scopes to determine if they have a given one
export function hasScope(p) {
  if (userinfo === undefined) {
    return false;
  }

  let ret = false;
  userinfo.scopes.forEach((s) => {
    if (s === p) {
      ret = true;
    }
  });
  return ret;
}
