import { defaultTheme } from "react-admin";
import merge from "lodash/merge";

export const bwColours = {
  primary: {
    red: "#6E0519",
    beige: "#D0B78C",
    offwhite: "#F8F2E9",
    offblack: "#464042",
  },
  secondary: {
    pink: "#B27B73",
    orange: "#AE7500",
    lightBlue: "#98B5C0",
    darkBlue: "#0F6074",
  },
  accent: {
    purple: "#85005B",
    green: "#006E52",
    blue: "#0B4F8A",
    brown: "#AE7500",
    pink: "#BA4157",
    red: "#A13918",
    teal: "#0084BA",
    mauve: "#7067CF",
    army: "#736903",
    orange: "#BF5C00",
  },
};

// We use this for clickable hyperlinks
// See SO2-1349, SO2-1447
const linkBlue = "#005AA8";

// Note: this is a ReactAdmin theme, which is similar but not identical to a MUI theme.
// The palette is the same, but the overrides structure is different.
export const theme = merge(defaultTheme, {
  palette: {
    primary: { main: bwColours.primary.red },
    secondary: { main: bwColours.secondary.darkBlue },
    background: { default: "#FFFAF2" },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: bwColours.primary.offblack,
    },
  },
  components: {
    RaReferenceField: {
      styleOverrides: {
        root: {
          "& .RaReferenceField-link>*": { color: linkBlue },
        },
      },
    },
    RaLink: {
      styleOverrides: {
        link: {
          color: linkBlue,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          color: linkBlue,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: linkBlue,
          textDecoration: "none",
        },
      },
    },
    RaPaginationActions: {
      styleOverrides: {
        currentPageButton: {
          color: "#aaa",
        },
        hellip: {
          color: "#aaa",
        },
      },
    },
    ToolTip: {
      popper: {
        fontSize: "140%",
        padding: "1em",
        paddingTop: "0px",
        paddingBottom: "0px",
        lineHeight: "150%",
      },
    },
  },
});
