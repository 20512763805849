import {
  Datagrid,
  List,
  NumberField,
  Pagination,
  TextField,
  ReferenceField,
  Show,
  TabbedShowLayout,
  Tab,
  FunctionField,
} from "react-admin";
import { CustomDatetimeField } from "../lib/customDatetimeField";
import { DetailsButton } from "../lib/detailsButton";
import LinePlot from "./charts/event_raman";

export const RamanMeasurementList = () => (
  <List perPage={25} pagination={<Pagination rowsPerPageOptions={[]} />}>
    <Datagrid bulkActionButtons={false}>
      <NumberField source="id" label="ID" sortable={false} />
      <CustomDatetimeField source="timestamp" label="Time" sortable={false} />
      <TextField source="ticket" label="Ticket" sortable={false} />
      <ReferenceField
        label="Sample"
        source="sampleID"
        reference="samples"
        link="show"
        sortable={false}
      >
        <FunctionField render={(rec) => `${rec.name} (#${rec.id})`} />
      </ReferenceField>
      <TextField source="notes" label="Notes" sortable={false} />
      <ReferenceField
        label="Device"
        source="deviceID"
        reference="devices"
        link="show"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <DetailsButton />
    </Datagrid>
  </List>
);

export const RamanMeasurementShow = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="Overview">
        <NumberField source="id" label="ID" />
        <CustomDatetimeField source="timestamp" label="Time" />
        <TextField source="ticket" label="Ticket" />
        <ReferenceField
          label="Sample"
          source="sampleID"
          reference="samples"
          link="show"
        >
          <FunctionField render={(rec) => `${rec.name} (#${rec.id})`} />
        </ReferenceField>
        <ReferenceField
          label="Device"
          source="deviceID"
          reference="devices"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
      </Tab>
      <Tab label="Spectra">
        <LinePlot />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
