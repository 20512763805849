import {
  Datagrid,
  List,
  NumberField,
  Pagination,
  TextField,
  SimpleForm,
  Edit,
  Create,
  TextInput,
  EditButton,
  NumberInput,
  ReferenceInput,
  Show,
  SimpleShowLayout,
  ReferenceField,
  AutocompleteInput,
  SelectInput,
  DateTimeInput,
  FunctionField,
} from "react-admin";
import { DetailsButton } from "../lib/detailsButton";
import { CustomDatetimeField } from "../lib/customDatetimeField";
import RangePickerInput from "../lib/dateRangePicker";

const analyteOptions = [
  { id: "gluc", name: "Glucose", unit: "g/L" },
  { id: "fruc", name: "Fructose", unit: "g/L" },
  { id: "sucr", name: "Sucrose", unit: "g/L" },
  { id: "mali", name: "Malic Acid", unit: "g/L" },
  { id: "tart", name: "Tartaric Acid", unit: "g/L" },
  { id: "tota", name: "Total Acidity", unit: "g/L" },
  { id: "tita", name: "Titratable Acidity", unit: "g/L" },
  { id: "acet", name: "Acetic Acid", unit: "g/L" },
  { id: "citr", name: "Citric Acid", unit: "g/L" },
  { id: "etoh", name: "Ethanol", unit: "g/L" },
  { id: "h2o", name: "Water", unit: "g/L" },
  { id: "brix", name: "Brix", unit: "°Bx" },
  { id: "pH", name: "pH", unit: "pH" },
  { id: "fso2", name: "Free SO2", unit: "g/L" },
  { id: "yan", name: "YAN", unit: "g/L" },
  { id: "pan", name: "PAN", unit: "g/L" },
  { id: "tyan", name: "Total YAN", unit: "g/L" },
];

export const renderResult = (rec) => {
  let result = rec.result.toFixed(2);
  analyteOptions.forEach((a) => {
    if (rec.analyte === a.id && a.unit !== undefined) {
      result += " " + a.unit;
    }
  });
  return result;
};

const methodOptions = [
  { id: "std", name: "Standard" },
  { id: "hplc", name: "HPLC" },
  { id: "foss", name: "FOSS" },
  { id: "refractometer", name: "Refractometer" },
  { id: "pH_meter", name: "pH Meter" },
  { id: "zaber_pH", name: "Zaber pH" },
  { id: "extlab", name: "External Lab" },
];

const analysisFilters = [
  <SelectInput source="analyte" choices={analyteOptions} />,
  <SelectInput source="method" choices={methodOptions} />,

  <RangePickerInput source="dateRange" label="Date" />,
];

export const AnalysisList = () => (
  <List
    perPage={25}
    pagination={<Pagination rowsPerPageOptions={[]} />}
    filters={analysisFilters}
  >
    <Datagrid bulkActionButtons={false}>
      <NumberField source="id" sortable={false} />
      <CustomDatetimeField source="timestamp" sortable={false} />
      <TextField source="analyte" sortable={false} />
      <TextField source="method" sortable={false} />
      <FunctionField
        label="Result"
        render={renderResult}
        sortable={false}
        textAlign="right"
        whiteSpace="nowrap"
      />
      <ReferenceField
        label="Sample"
        source="sampleID"
        reference="samples"
        link="show"
        sortable={false}
      >
        <FunctionField render={(rec) => `${rec.name} (#${rec.id})`} />
      </ReferenceField>
      <TextField source="notes" sortable={false} />
      <EditButton />
      <DetailsButton />
    </Datagrid>
  </List>
);

const CreateEditForm = () => (
  <SimpleForm>
    <DateTimeInput source="timestamp" />
    <SelectInput
      source="analyte"
      optionText={(opt) => opt.name + (opt.unit ? ` (${opt.unit})` : "")}
      choices={analyteOptions}
      onCreate={() => {
        const val = prompt("Enter a new analyte code");
        const newObj = { id: val.toLowerCase(), name: val };
        analyteOptions.push(newObj); // Will persist for this session only
        return newObj;
      }}
    />
    <SelectInput
      source="method"
      choices={methodOptions}
      onCreate={() => {
        const val = prompt("Enter a new method code");
        const newObj = { id: val.toLowerCase(), name: val };
        methodOptions.push(newObj); // Will persist for this session only
        return newObj;
      }}
    />
    <NumberInput source="result" />
    <ReferenceInput
      label="Sample"
      source="sampleID"
      reference="samples"
      link="show"
    >
      <AutocompleteInput
        label="Sample"
        optionText={(rec) => `${rec.name} (#${rec.id})`}
        style={{ width: 300 }}
        filterToQuery={(searchText) => ({ name: searchText })}
      />
    </ReferenceInput>
    <TextInput source="notes" multiline fullWidth />
  </SimpleForm>
);

export const AnalysisCreate = () => (
  <Create redirect="list">
    <CreateEditForm />
  </Create>
);

export const AnalysisEdit = () => (
  <Edit redirect="list">
    <CreateEditForm />
  </Edit>
);

export const AnalysisShow = () => (
  <Show>
    <SimpleShowLayout>
      <NumberField source="id" />
      <TextField source="analyte" />
      <TextField source="method" />
      <FunctionField label="Result" render={renderResult} whiteSpace="nowrap" />
      <ReferenceField
        label="Sample"
        source="sampleID"
        reference="samples"
        link="show"
      >
        <FunctionField
          render={(rec) => `${rec.name} (#${rec.id})`}
          style={{ width: 300 }}
        />
      </ReferenceField>
      <TextField source="notes" />
    </SimpleShowLayout>
  </Show>
);
