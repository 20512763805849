import { React } from "react";
import {
  Create,
  Datagrid,
  List,
  Pagination,
  SimpleForm,
  TextField,
  SelectInput,
  NumberField,
} from "react-admin";
import { FileStatusField } from "./documents";
import { CustomDatetimeField } from "../lib/customDatetimeField";

export const TaskList = () => (
  <List
    perPage={25}
    pagination={<Pagination rowsPerPageOptions={[]} />}
    exporter={false}
    sort={{ field: "startTime", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" label="ID" sortable={false} />
      <CustomDatetimeField source="startTime" label="Time" sortable={false} />
      <TextField source="type" label="Type" sortable={false} />
      <FileStatusField source="status" sortable={false} />
      <TextField source="error" label="Error" sortable={false} />
      <NumberField source="runtime" label="Runtime" sortable={false} />
    </Datagrid>
  </List>
);

const taskOptions = [
  { id: "innovint_inbound", name: "InnoVint - Inbound" },
  /*
  Keep this here in case it will be used in the future.
  {
    id: "innovint_outbound_fso2",
    name: "InnoVint - Outbound Free SO2",
  },*/
  {
    id: "innovint_outbound_virtual_composite",
    name: "InnoVint - Outbound Virtual Composite",
  },
  { id: "vintrace_inbound", name: "Vintrace - Inbound" },
];

export const TaskCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <SelectInput label="Type" source="type" choices={taskOptions} />
    </SimpleForm>
  </Create>
);
