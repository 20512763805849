import {
  Datagrid,
  List,
  NumberField,
  Pagination,
  TextField,
  SimpleForm,
  Edit,
  Create,
  TextInput,
  EditButton,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  Show,
  TabbedShowLayout,
  Tab,
  SelectInput,
  DateTimeInput,
  ReferenceManyField,
  ReferenceInput,
  AutocompleteInput,
  FunctionField,
  ArrayField,
  ReferenceField,
  SingleFieldList,
  SelectField,
} from "react-admin";

import { DetailsButton } from "../lib/detailsButton";
import {
  CustomDatetimeField,
  CustomDateField,
} from "../lib/customDatetimeField";
import { renderResult } from "./analysis";
import RangePickerInput from "../lib/dateRangePicker";

const sourceOptions = [
  { id: "table_grape", name: "Table Grape" },
  { id: "wine_grape", name: "Wine Grape" },
  { id: "store_juice", name: "Store Juice" },
  { id: "wine_juice", name: "Wine Juice" },
  { id: "standard", name: "Standard" },
];

// Displays the sample's recipe in a mini table
export const RecipeField = ({ style }) => (
  <div style={style}>
    <ArrayField source="recipe">
      <SingleFieldList
        linkType={false}
        style={{ flexDirection: "column", gap: 5, padding: "5px 0" }}
      >
        <div
          style={{
            paddingTop: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ReferenceField
            source="sampleID"
            reference="samples"
            link="show"
            emptyText="Unknown"
          >
            <FunctionField
              render={(rec) => `${rec.name} (#${rec.id})`}
              style={{ whiteSpace: "nowrap", paddingRight: 5 }}
            />
          </ReferenceField>
          <FunctionField
            render={(rec) => `${rec.volume_ml} mL`}
            style={{ whiteSpace: "nowrap" }}
          />
        </div>
      </SingleFieldList>
    </ArrayField>
  </div>
);

const sampleFilters = [
  <SelectInput source="source" choices={sourceOptions} />,
  <ReferenceInput
    source="jira"
    reference="datasets"
    recordRepresentation="jira"
  >
    <SelectInput optionText="jira" optionValue="jira" />
  </ReferenceInput>,
  <RangePickerInput source="dateRange" label="Date" />,
];

export const SampleList = () => (
  <List
    perPage={25}
    pagination={<Pagination rowsPerPageOptions={[]} filters={sampleFilters} />}
    filters={sampleFilters}
  >
    <Datagrid bulkActionButtons={false}>
      <NumberField source="id" sortable={false} />
      <TextField
        source="name"
        sortable={false}
        style={{ whiteSpace: "nowrap" }}
      />
      <TextField
        source="jira"
        sortable={false}
        style={{ whiteSpace: "nowrap" }}
      />
      <CustomDateField source="createdOn" sortable={false} />
      <SelectField source="source" choices={sourceOptions} sortable={false} />
      <RecipeField label="Recipe" sortable={false} />
      <EditButton />
      <DetailsButton />
    </Datagrid>
  </List>
);

const CreateEditForm = () => (
  <SimpleForm>
    <TextInput source="name" />
    <TextInput source="jira" />
    <DateTimeInput source="createdOn" />
    <SelectInput
      source="source"
      choices={sourceOptions}
      onCreate={() => {
        const val = prompt("Enter a new source code");
        const newObj = { id: val.toLowerCase(), name: val };
        sourceOptions.push(newObj); // Will persist for this session only
        return newObj;
      }}
    />
    <TextInput source="prep" multiline fullWidth />
    <TextInput source="variety" />
    <ArrayInput source="recipe">
      <SimpleFormIterator inline>
        <ReferenceInput
          source="sampleID"
          reference="samples"
          helperText={false}
        >
          <AutocompleteInput
            label="Sample"
            optionText={(rec) => `${rec.name} (#${rec.id})`}
            style={{ width: 300 }}
            filterToQuery={(searchText) => ({ name: searchText })}
          />
        </ReferenceInput>
        <NumberInput
          label="Volume (mL)"
          source="volume_ml"
          helperText={false}
        />
      </SimpleFormIterator>
    </ArrayInput>
    <TextInput source="notes" multiline fullWidth />
  </SimpleForm>
);

export const SampleCreate = () => (
  <Create redirect="list">
    <CreateEditForm />
  </Create>
);

export const SampleEdit = () => (
  <Edit redirect="list">
    <CreateEditForm />
  </Edit>
);

const AnalysisList = () => (
  <>
    <Datagrid bulkActionButtons={false}>
      <CustomDatetimeField source="timestamp" label="Time" sortable={false} />
      <TextField source="analyte" />
      <TextField source="method" />
      <FunctionField
        label="Result"
        render={renderResult}
        sortable={false}
        textAlign="right"
        whiteSpace="nowrap"
      />
      <TextField source="notes" />
      <DetailsButton />
    </Datagrid>
    <Pagination rowsPerPageOptions={[]} />
  </>
);

const RamanMeasurementList = () => (
  <>
    <Datagrid bulkActionButtons={false}>
      <NumberField source="id" label="ID" sortable={false} />
      <CustomDatetimeField source="timestamp" label="Time" sortable={false} />
      <TextField source="ticket" label="Ticket" sortable={false} />
      <TextField source="notes" label="Notes" sortable={false} />
      <ReferenceField
        label="Device"
        source="deviceID"
        reference="devices"
        link="show"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <DetailsButton />
    </Datagrid>
  </>
);

export const SampleShow = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="Overview">
        <NumberField source="id" />
        <TextField source="name" />
        <TextField source="jira" />
        <CustomDatetimeField source="createdOn" />
        <SelectField source="source" choices={sourceOptions} sortable={false} />
        <TextField source="prep" />
        <TextField source="variety" />
        <RecipeField
          label="Recipe"
          style={{ width: 300, border: "1px solid #f3f3f3", padding: 10 }}
        />
        <TextField source="notes" />
      </Tab>
      <Tab label="Analysis">
        <ReferenceManyField reference="analysis" target="sampleID" label="">
          <AnalysisList />
        </ReferenceManyField>
      </Tab>
      <Tab label="Measurements">
        <ReferenceManyField
          reference="events/raman_measurement"
          target="sampleID"
          label=""
        >
          <RamanMeasurementList />
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
