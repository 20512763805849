function GenerateGradientColors(steps) {
  const colorList = [];

  const startColor = "rgb(116, 241, 253)";
  const endColor = "rgb(240, 112, 249)";

  // Parse the start and end colors
  const start = parseColor(startColor);
  const end = parseColor(endColor);

  // Calculate the step size for each color channel (RGB)
  const stepR = (end.r - start.r) / steps;
  const stepG = (end.g - start.g) / steps;
  const stepB = (end.b - start.b) / steps;

  // Generate the gradient colors
  for (let i = 0; i < steps; i++) {
    const r = start.r + i * stepR;
    const g = start.g + i * stepG;
    const b = start.b + i * stepB;

    colorList.push(`rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`);
  }

  return colorList;
}

function parseColor(color) {
  const matches = color.match(/\d+/g);
  if (!matches || matches.length < 3) {
    throw new Error("Invalid color format");
  }

  return {
    r: parseInt(matches[0], 10),
    g: parseInt(matches[1], 10),
    b: parseInt(matches[2], 10),
  };
}

export default GenerateGradientColors;
