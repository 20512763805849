import React from "react";
import { CustomDateField } from "../lib/customDatetimeField";
import { List, Datagrid, TextField } from "react-admin";
import { useRecordContext } from "react-admin";
import Link from "@mui/material/Link";

const NumSamplesField = () => {
  const record = useRecordContext();
  const samplesListPage = `/samples?displayedFilters=${JSON.stringify({
    jira: true,
  })}&filter=${JSON.stringify({
    jira: record.jira,
  })}`;

  return <Link href={samplesListPage}>{record.numSamples}</Link>;
};

export const DatasetList = (props) => (
  <List {...props} pagination={null}>
    <Datagrid rowClick={false} bulkActionButtons={false}>
      <TextField source="id" label="ID" sortable={false} />
      <TextField source="jira" label="Jira" sortable={false} />
      <NumSamplesField label="Samples" sortable={false} />
      <CustomDateField source="timestamp" label="Date" sortable={false} />
    </Datagrid>
  </List>
);
