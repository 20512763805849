import React, { useEffect } from "react";
import { Admin, Resource } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";

import RouterIcon from "@mui/icons-material//Router";
import BusinessIcon from "@mui/icons-material//Business";
import PersonIcon from "@mui/icons-material//Person";
import ScienceIcon from "@mui/icons-material/Science";
import ImageIcon from "@mui/icons-material/Image";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import AppsIcon from "@mui/icons-material/Apps";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
/*
import ArchiveIcon from "@mui/icons-material//Archive";
import PersonPin from "@mui/icons-material//PersonPin";
import Whatshot from "@mui/icons-material//Whatshot";
import NatureIcon from "@mui/icons-material//Nature";
import EqualizerIcon from "@mui/icons-material//Equalizer";
import InfoIcon from "@mui/icons-material//Info";
import ScatterPlotIcon from "@mui/icons-material//ScatterPlot";
*/

import { fetchJson } from "./lib/httpClient";
import { authProvider, setScopesCustomerID } from "./lib/auth";
import { theme } from "./lib/theme";
import AppLayout from "./lib/appLayout";
import { setCustInfo } from "./lib/customer";

import { DeviceList, DeviceEdit, DeviceCreate } from "./resources/device";
/*
import {
  MetaDataList,
  MetaDataEdit,
  MetaDataCreate,
} from "./resources/metadata";
*/

import {
  CustomerList,
  CustomerEdit,
  CustomerCreate,
} from "./resources/customer";
//import { FirmwareList } from "./resources/firmware";
import { UserList, UserEdit, UserCreate } from "./resources/user";
/*
import {
  VarietalList,
  VarietalEdit,
  VarietalCreate,
} from "./resources/varietals";
*/

import { RamanMeasurementList, RamanMeasurementShow } from "./resources/raman";
import {
  AnalysisCreate,
  AnalysisList,
  AnalysisShow,
  AnalysisEdit,
} from "./resources/analysis";
import {
  TrayLoadList,
  TrayLoadCreate,
  TrayLoadShow,
  TrayLoadEdit,
} from "./resources/tray_load";
import { DatasetList } from "./resources/datasets";
import {
  SampleCreate,
  SampleList,
  SampleShow,
  SampleEdit,
} from "./resources/samples";
const dataProvider = simpleRestProvider("/api/v2", fetchJson);

export const AdminApp = () => {
  useEffect(() => {
    document.title = "BarrelWise - Admin";
  }, []);

  // Unset some global state used by app-customer.js
  setCustInfo(undefined);
  setScopesCustomerID(undefined);

  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={AppLayout}
      theme={theme}
      loginPage={() => null}
    >
      <Resource
        name="customers"
        list={CustomerList}
        create={CustomerCreate}
        edit={CustomerEdit}
        icon={BusinessIcon}
      />
      <Resource
        name="users"
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
        icon={PersonIcon}
      />
      <Resource
        name="devices"
        list={DeviceList}
        create={DeviceCreate}
        edit={DeviceEdit}
        icon={RouterIcon}
      />
      <Resource name="datasets" list={DatasetList} icon={DynamicFeedIcon} />
      <Resource
        name="samples"
        list={SampleList}
        create={SampleCreate}
        edit={SampleEdit}
        icon={ScienceIcon}
        show={SampleShow}
      />
      <Resource
        name="events/raman_measurement"
        options={{ label: "Measurements" }}
        list={RamanMeasurementList}
        show={RamanMeasurementShow}
        icon={ImageIcon}
      />
      <Resource
        name="analysis"
        options={{ label: "Analyses" }}
        list={AnalysisList}
        create={AnalysisCreate}
        show={AnalysisShow}
        edit={AnalysisEdit}
        icon={TroubleshootIcon}
      />
      <Resource
        name="tray_load"
        options={{ label: "Tray Loads" }}
        list={TrayLoadList}
        create={TrayLoadCreate}
        show={TrayLoadShow}
        edit={TrayLoadEdit}
        icon={AppsIcon}
      />
      {/* 
      <Resource name="firmware" list={FirmwareList} icon={ArchiveIcon} />
      <Resource
        name="barrel_cooper"
        list={MetaDataList}
        create={MetaDataCreate}
        edit={MetaDataEdit}
        icon={PersonPin}
      />
      <Resource
        name="barrel_toast"
        list={MetaDataList}
        create={MetaDataCreate}
        edit={MetaDataEdit}
        icon={Whatshot}
      />
      <Resource
        name="barrel_oak"
        list={MetaDataList}
        create={MetaDataCreate}
        edit={MetaDataEdit}
        icon={InfoIcon}
      />
      <Resource
        name="barrel_forest"
        list={MetaDataList}
        create={MetaDataCreate}
        edit={MetaDataEdit}
        icon={NatureIcon}
      />
      <Resource
        name="barrel_type"
        list={MetaDataList}
        create={MetaDataCreate}
        edit={MetaDataEdit}
        icon={EqualizerIcon}
      />
      <Resource
        name="varietals"
        list={VarietalList}
        create={VarietalCreate}
        edit={VarietalEdit}
        icon={ScatterPlotIcon}
      />
      */}
    </Admin>
  );
};
