import React from "react";
import {
  Create,
  Datagrid,
  List,
  Pagination,
  SimpleForm,
  TextField,
  FileField,
  FileInput,
  useRecordContext,
} from "react-admin";
import { CustomDatetimeField } from "../lib/customDatetimeField";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

export const FileStatusField = (props) => {
  const rec = useRecordContext();

  let color;
  if (rec.status === "success") {
    color = "#00A87B";
  } else if (rec.status === "pending") {
    color = "#BF5C00";
  } else {
    color = "#A20021";
  }
  return rec.status === "error" ? (
    <Tooltip
      title={
        <p
          style={{
            fontSize: "1.8em",
            lineHeight: 1,
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          {rec.error}
        </p>
      }
    >
      <Typography
        variant="body2"
        component="span"
        noWrap={true}
        sx={{ color: color }}
      >
        {rec.status}
      </Typography>
    </Tooltip>
  ) : (
    <Typography
      variant="body2"
      component="span"
      noWrap={true}
      sx={{ color: color }}
    >
      {rec.status}
    </Typography>
  );
};

export const DocumentList = () => (
  <List
    perPage={25}
    pagination={<Pagination rowsPerPageOptions={[]} />}
    exporter={false}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" label="Name" sortable={false} />
      <CustomDatetimeField source="timestamp" label="Time" sortable={false} />
      <TextField source="format" label="Format" sortable={false} />
      <TextField source="sizeKB" label="Size (KB)" sortable={false} />
      <FileStatusField source="status" sortable={false} />
    </Datagrid>
  </List>
);

const DocumentMessage = () => {
  return (
    <>
      <p>Upload .csv file (Max. 10MB)</p>
      <span>
        Header should have the following:
        <p>
          Blend Name, Blend Creation Date, Vintage Year, Varietal, Wine Color,
          Lot Name, Lot Creation Date, Addition Target, Minimum Addition,
          Maximum Addition, Addition Multiplier, Intended Use, Barcode, Alias,
          Cooper, Toast, Oak, Forest, Label, Volume, Volume Unit, Barrel Year,
          Seasoning, Drying Time, Toasted Head, Grain, Type
        </p>
      </span>
    </>
  );
};

export function DocumentCreate() {
  return (
    <Create redirect="list">
      <SimpleForm>
        {/* Unit is in bytes */}
        <DocumentMessage />
        <FileInput source="attachment" accept=".csv" maxSize={100000000}>
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
}
